.quickBilling-uploader .ant-upload {
    width: 100%;
    height: 700px;
    display: block;
    // margin-left: 20px;
    background: url(../../assets/images/quickBillingBg.jpg) no-repeat center;
    background-size: 90% 90%;
}

.quickBilling-uploader .ant-upload-list-picture-card-container {
    width: 100%;
    height: 700px;
    display: block;
    // margin-left: 20px;
    background-color:#FAFAFC;
}

.quickBilling-uploader .ant-upload-list-picture-card-container .ant-upload-list-item-list-type-picture-card .ant-upload-list-item-info {
    width: 0px;
}

.quickBox {
    display: flex;
    padding:  0 20px;
    .photo{
        flex: 1;
    }
    .form{
        flex: 1
    }
    .ant-row{
        // margin-bottom: 10px;
    }
    .ant-form-item{
        margin: 4px !important;
    }
}

@primary-color: #2e3aeb;@form-item-margin-bottom: 20px;@background-color-base: rgba(0, 0, 0, 0);@disabled-color: #808A87;@text-color: rgba(0, 0, 0, 0.65);@table-selected-row-hover-bg: #e9ecfb;@table-row-hover-bg: #e9ecfb;@table-header-bg: #d7defe;@table-header-color: #6b6b6b;@table-padding-vertical: 10px;@table-padding-horizontal: 6px;