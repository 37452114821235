.box {
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    right: 0;
    background: rgba(0, 0, 0, 0.6);
    z-index: 1000;
}

.news-box {
    width: 1266px;
    height: 688px;
    box-shadow: 0px 12px 52px 0px rgba(52, 139, 255, 0.56);
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: 2000px;
    animation: slideIn 1s forwards;
}

.news-box-bg {
    width: 100%;
    height: 100%;
    display: block;
}

.news-box-cattle {
    width: 500px;
    position: absolute;
    left: 30px;
    top: 252px;
}

.news-box-info {
    width: 588px;
    height: 400px;
    position: absolute;
    // background-color: red;
    right: 80px;
    top: 150px;
}

.news-box-info-title {
    height: 50px;
    font-family: PingFangSC, PingFang SC;
    font-weight: 500;
    font-size: 36px;
    color: #ffffff;
    line-height: 50px;
    text-align: left;
    font-style: normal;
}

.news-box-info-main {
    font-family: PingFangSC, PingFang SC;
    font-weight: 400;
    font-size: 28px;
    color: #ffffff;
    line-height: 60px;
    text-align: left;
    font-style: normal;
    height: 274px;
    overflow-y: auto;
}

.news-box-btns {
    width: 588px;
    overflow: hidden;
    margin-top: 10px;
}

.news-box-hand {
    width: 128px;
    height: 60px;
    background: linear-gradient(165deg, #ffffff 0%, #bcc8f3 100%);
    border-radius: 8px;
    border: 2px solid #e5e9fa;
    font-family: PingFangSC, PingFang SC;
    font-weight: 400;
    font-size: 24px;
    color: #0036ff;
    text-align: left;
    font-style: normal;
    text-align: center;
    line-height: 60px;
    float: left;
    cursor: pointer;
}

.news-box-auto {
    width: 178px;
    height: 60px;
    background: linear-gradient(165deg, #538fff 0%, #2956fe 100%);
    border-radius: 8px;
    border: 2px solid #3769fe;
    font-family: PingFangSC, PingFang SC;
    font-weight: 500;
    font-size: 24px;
    color: #ffffff;
    line-height: 34px;
    text-align: left;
    font-style: normal;
    text-align: center;
    line-height: 60px;
    float: left;
    margin-left: 16px;
    cursor: pointer;
}

.dispatch-box {
    width: 680px;
    height: 724px;
    box-shadow: 0px 12px 52px 0px rgba(52, 139, 255, 0.56);
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: 2000px;
    animation: slideIn 1s forwards;
}

.dispatch-box-bg {
    width: 100%;
    height: 100%;
    display: block;
}

.dispatch-box-cattle {
    width: 184px;
    position: absolute;
    left: -20px;
    top: 78px;
}

.dispatch-main {
    width: 450px;
    position: absolute;
    right: 48px;
    top: 56px;
}

.dispatch-box-dispatch-btns {
    overflow: hidden;
}

.dispatch-box-dispatch {
    width: 136px;
    height: 60px;
    background: linear-gradient(165deg, #ffffff 0%, #bcc8f3 100%);
    border-radius: 8px;
    border: 2px solid #e5e9fa;
    font-family: PingFangSC, PingFang SC;
    font-weight: 400;
    font-size: 24px;
    color: #0036ff;
    line-height: 34px;
    text-align: left;
    font-style: normal;
    float: right;
    text-align: center;
    line-height: 60px;
    cursor: pointer;
}

.dispatch-box-cancel {
    width: 136px;
    height: 60px;
    background: linear-gradient(165deg, #538fff 0%, #2956fe 100%);
    border-radius: 8px;
    border: 2px solid #3769fe;
    font-family: PingFangSC, PingFang SC;
    font-weight: 500;
    font-size: 24px;
    color: #ffffff;
    line-height: 34px;
    text-align: left;
    font-style: normal;
    float: right;
    text-align: center;
    line-height: 60px;
    margin-right: 24px;
    cursor: pointer;
}

.loading {
    display: flex;
    margin-top: 10px;
}

.dot {
    width: 12px;
    height: 12px;
    margin: 0 4px;
    background-color: white;
    border-radius: 50%;
    display: inline-block;
    animation: bounce 1.2s infinite;
}

.dot:nth-child(1) {
    animation-delay: 0s;
}

.dot:nth-child(2) {
    animation-delay: 0.4s;
}

.dot:nth-child(3) {
    animation-delay: 0.8s;
}

@keyframes bounce {
    0%,
    100% {
        opacity: 0.2;
        transform: scale(1);
    }
    50% {
        opacity: 1;
        transform: scale(1.3);
    }
}

.loading-icon {
    display: inline-block;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    border: 4px solid rgba(255, 255, 255, 0.1); /* 外边框颜色，透明度可以根据需要调整 */
    border-top-color: #fff; /* 顶部边框颜色，即加载图标的“活动”部分 */
    animation: spin 1s linear infinite; /* 旋转动画 */
}

@keyframes spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

.success-box {
    width: 680px;
    height: 400px;
    box-shadow: 0px 12px 52px 0px rgba(52, 139, 255, 0.56);
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: 2000px;
    animation: slideIn 1s forwards;
}

.success-box-bg {
    width: 100%;
    height: 100%;
    display: block;
}

.success-box-cattle {
    width: 187px;
    position: absolute;
    left: 30px;
    top: 90px;
}

.success-box-info {
    width: 350px;
    position: absolute;
    right: 20px;
    top: 114px;
    img {
        display: block;
        width: 100px;
        height: 100px;
        margin: 0 auto 22px auto;
    }
    p {
        font-family: PingFangSC, PingFang SC;
        font-weight: 500;
        font-size: 28px;
        color: #ffffff;
        line-height: 44px;
        text-align: center;
        font-style: normal;
    }
}

.choose-dispatch-type-box {
    width: 680px;
    height: 400px;
    box-shadow: 0px 12px 52px 0px rgba(52, 139, 255, 0.56);
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: 2000px;
    animation: slideIn 1s forwards;
}

.choose-dispatch-type-box-bg {
    width: 100%;
    height: 100%;
    display: block;
}

.choose-dispatch-type-box-cattle {
    width: 187px;
    position: absolute;
    left: 30px;
    top: 90px;
}

.choose-dispatch-type-box-info {
    width: 368px;
    position: absolute;
    right: 40px;
    top: 100px;
}

.choose-dispatch-type-title {
    font-family: PingFangSC, PingFang SC;
    font-weight: 400;
    font-size: 26px;
    color: #ffffff;
    line-height: 44px;
    text-align: left;
    font-style: normal;
}

.choose-dispatch-type-content {
    font-family: PingFangSC, PingFang SC;
    font-weight: 600;
    font-size: 28px;
    color: #ffffff;
    line-height: 60px;
    text-align: left;
    font-style: normal;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

.common-close {
    width: 64px;
    height: 104px;
    position: absolute;
    right: 22px;
    top: -104px;
    cursor: pointer;
}

@keyframes slideIn {
    0% {
        bottom: 2000px; /* 起始位置 */
    }
    100% {
        bottom: 50%; /* 滑动到页面中央 */
        transform: translateX(-50%) translateY(50%); /* 确保div在垂直方向上居中 */
    }
}

@primary-color: #2e3aeb;@form-item-margin-bottom: 20px;@background-color-base: rgba(0, 0, 0, 0);@disabled-color: #808A87;@text-color: rgba(0, 0, 0, 0.65);@table-selected-row-hover-bg: #e9ecfb;@table-row-hover-bg: #e9ecfb;@table-header-bg: #d7defe;@table-header-color: #6b6b6b;@table-padding-vertical: 10px;@table-padding-horizontal: 6px;