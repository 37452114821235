/* 地图搜索 */
.map-box {
  position: relative;
}
.container {
  width: 100%;
  height: 500px;
}
.panel {
  position: absolute;
  width: 300px;
  top: 30px;
  top: 100px;
  left: 30px;
  z-index: 9999;
}
.suggestionList {
  list-style: none;
  margin: 0;
  background: #fff;
  position: absolute;
  left: 0;
  top: 40px;
  z-index: 9999999;
  width: 100%;
}
.suggestionList li {
  list-style: none;
  margin-top: 2px;
  background-color: #f6f6f6;
  text-decoration: none;
  font-size: 14px;
  color: black;
  display: block;
  cursor: pointer;
  padding: 10px;
}
.suggestionList li .item_info {
  font-size: 12px;
  color: grey;
}
.suggestionList li a:hover:not(.header) {
  background-color: #eee;
}
