.statistics {
    display: flex;
    justify-content: space-between;
    .item {
        border-right: 1px solid rgba(98, 98, 98, 0.2);
        text-align: center;
        padding: 0 24px;
        flex: 1;
        align-self: center;

        span {
            color: rgba(0, 0, 0, 0.45);
            display: inline-block;
            font-size: 14px;
            margin-bottom: 4px;
        }

        p {
            color: rgba(0, 0, 0, 0.65);
            font-size: 24px;
            margin: 0;
        }
    }
}
.team-but-left {
    background-color: rgb(189, 199, 252) !important;
    color: rgb(107, 107, 107) !important;
    border: none !important;
    padding: 0px 20px;
    margin-right: 14px;
    color: #fff;
    font-weight: bold;
}

@primary-color: #2e3aeb;@form-item-margin-bottom: 20px;@background-color-base: rgba(0, 0, 0, 0);@disabled-color: #808A87;@text-color: rgba(0, 0, 0, 0.65);@table-selected-row-hover-bg: #e9ecfb;@table-row-hover-bg: #e9ecfb;@table-header-bg: #d7defe;@table-header-color: #6b6b6b;@table-padding-vertical: 10px;@table-padding-horizontal: 6px;