#home-index-dispath {

    .ant-form-item-required {
        font-family: PingFangSC, PingFang SC;
        font-weight: 400;
        font-size: 24px;
        color: #fff !important;
        line-height: 34px;
        text-align: left;
        font-style: normal;
        line-height: 56px;
        height: 56px;
    }

    .ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
        font-family: PingFangSC, PingFang SC;
        font-weight: 400;
        font-size: 24px;
        color: #34dfff;
        line-height: 34px;
        text-align: left;
        font-style: normal;
    }

    .ant-form-item-label > label::after {
        content: '';
    }

    .ant-form-horizontal .ant-form-item-control {
    }

    .ant-select:not(.ant-select-customize-input) .ant-select-selector {
        background-color: #1f3693;
        height: 56px;
        font-family: PingFangSC, PingFang SC;
        font-weight: 400;
        font-size: 24px;
        color: #ffffff;
        line-height: 56px;
        text-align: left;
        font-style: normal;
        border: none;
        border-radius: 8px;
    }

    .ant-select-single.ant-select-show-arrow .ant-select-selection-item,
    .ant-select-single.ant-select-show-arrow .ant-select-selection-placeholder {
        line-height: 56px;
    }

    



    
}


@primary-color: #2e3aeb;@form-item-margin-bottom: 20px;@background-color-base: rgba(0, 0, 0, 0);@disabled-color: #808A87;@text-color: rgba(0, 0, 0, 0.65);@table-selected-row-hover-bg: #e9ecfb;@table-row-hover-bg: #e9ecfb;@table-header-bg: #d7defe;@table-header-color: #6b6b6b;@table-padding-vertical: 10px;@table-padding-horizontal: 6px;