.form-details-warp {
    .ant-tabs .ant-tabs-nav {
        width: calc(100vw - 200px);
        position: absolute !important;
        top: 0px;
        z-index: 50;
        background: #f9faff;
    }

    .ant-tabs-content-holder{
        padding-top: 50px;
    }
    .ant-form-item{
        margin-bottom: 15px !important;
    }
}

.bottom-info{
    // display: flex;
    span{
        margin-right: 30px;
    }
}

.reviewFlag{
    width: 120px;
    position: absolute;
    top: 10px;
    right: 20px;
    z-index: 999;
}


@primary-color: #2e3aeb;@form-item-margin-bottom: 20px;@background-color-base: rgba(0, 0, 0, 0);@disabled-color: #808A87;@text-color: rgba(0, 0, 0, 0.65);@table-selected-row-hover-bg: #e9ecfb;@table-row-hover-bg: #e9ecfb;@table-header-bg: #d7defe;@table-header-color: #6b6b6b;@table-padding-vertical: 10px;@table-padding-horizontal: 6px;