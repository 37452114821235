.yh-header {
    padding: 10px 16px 4px;
    display: flex;
    flex-flow: row nowrap;
    .modules-name {
        display: inline-block;
        font-weight: bold;
        color: #7d7d7d;
        min-width: 120px;
        margin-right: 15px;
        padding-top: 2px;
    }
    /*订单搜索*/
    .pub-search-box {
        display: inline-block;
        flex: 1;
        vertical-align: top;
        .ant-col {
            // line-height: 1;
            // height: 32px;
        }
    }
    .pub-search-right {
        display: inline-block;
        margin-left: 14px;
        & :first-child {
            margin-right: 12px;
        }
        .btn-item {
            display: inline-block;
            width: 100px;
            text-align: center;
            line-height: 24px;
            color: #6b6b6b;
            background: rgba(255, 255, 255, 1);
            border: 1px solid rgba(215, 222, 254, 1);
            border-radius: 16px;
            font-weight: bold;
            cursor: pointer;
        }
        .btn-item:hover {
            background: #bdc7fc;
        }
        .added-btn {
            display: inline-block;
            width: 50px;
            text-align: center;
            color: #2d39eb;
            cursor: pointer;
        }
    }
    .yh-search-select:not(.ant-select-customize-input) .ant-select-selector {
        border-radius: 16px;
        border: 1px solid rgba(215, 222, 254, 0) !important;
    }
    .ant-select-selection-placeholder {
        color: #BDC7FC;
    }
    /*表单控件覆盖样式*/
    .yh-search-input {
        width: 100%; // height: 26px;
        line-height: 24px;
        border: 1px solid rgba(215, 222, 254, 1);
        border-radius: 16px;
        vertical-align: middle;
    }
    .yh-search-select {
        width: 100%;
        border-radius: 16px;
        border: 1px solid rgba(215, 222, 254, 1);
        overflow: hidden;
    }
    /*input的placegolder的字体颜色*/
    input::-webkit-input-placeholder {
        /*Webkit browsers*/
        color: #bdc6fb;
    }
    :-moz-placeholder {
        /*Mozilla Firefox 4 to 8*/
        color: #bdc6fb;
    }
    ::moz-placeholder {
        /*Mozilla Firefox 19+*/
        color: #bdc6fb;
    }
    :-ms-input-placeholder {
        /*Internet Explorer 10+*/
        color: #bdc6fb;
    }
}
@primary-color: #2e3aeb;@form-item-margin-bottom: 20px;@background-color-base: rgba(0, 0, 0, 0);@disabled-color: #808A87;@text-color: rgba(0, 0, 0, 0.65);@table-selected-row-hover-bg: #e9ecfb;@table-row-hover-bg: #e9ecfb;@table-header-bg: #d7defe;@table-header-color: #6b6b6b;@table-padding-vertical: 10px;@table-padding-horizontal: 6px;