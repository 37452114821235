.container-fixed {
    position: fixed !important;
    z-index: 999;
    width: calc(100% - 220px) !important;
    // margin-top: -8px;
    top:116px;
}

.tab-title {
    margin-left: 20px;
}
.tab-actived {
    padding: 3px 10px;
    background-color: #1890ff;
    border-radius: 5px;
    font-size: 16px;
    color: #ffffff;
}

.data-tabs {
    width: 90%;
    position: relative;
    margin-top: 20px;
    margin-left: 10px;
    .data-tabs-title {
        display: flex;
        flex-wrap: wrap;
    }
    .data-tab-btn-actived {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
    .data-tab-btn {
        position: absolute;
        top: 0;
        right: -54px;
        color: #1890ff;
    }
}
// 总览卡片
.data-container {
    margin-top: 30px;
    display: flex;
    flex-wrap: wrap;
    border-top: 1px dashed #f7f9fa;
    .data-card {
        width: 250px;
        height: 180px;
        margin-left: 20px;
        margin-top: 20px;
        border-radius: 5px;
        background-color: #fff;
        position: relative;
        padding: 20px 0 0 0;
        .data-title {
            margin-left: 20px;
            color: #000;
            font-size: 16px;
        }
        .data-content {
            display: flex;
            margin: 10px;
            justify-content: space-around;
            .data-like {
                display: flex;
                flex-direction: column;
                .data-num {
                    color: #000;
                    font-size: 26px;
                    margin-top: 5px;
                }
            }
        }
        .data-footer {
            margin-top: 28px;
            height: 30px;
            background-color: #f7f9fa;
        }
    }
}

.list-container {
    position: relative;
    overflow: hidden;
    background: #fff;
    box-shadow: 0px 5px 12px 3px rgba(33, 33, 33, 0.1);
    border-radius: 10px;
    margin: 6px 10px 10px;

    .list-header-but {
        margin: 20px 0 20px 30px;
        padding-bottom: 10px;
        border-bottom: 2px solid #c2c2c2;
    }
    .list-header {
        padding: 10px;
        .list-name {
            font-size: 18px;
            font-weight: bold;
            color: #434343;
            .total-price {
                margin-left: 50px;
                font-size: 14px;
                span {
                    font-size: 18px;
                    color: #ff0000;
                }
            }
            .list-name-but {
                display: inline-block;
                float: right;
                span {
                    cursor: pointer;
                }
                .list-delete {
                    display: inline-block;
                    width: 93px;
                    text-align: center;
                    line-height: 24px;
                    font-size: 14px;
                    font-weight: 500;
                    color: #6b6b6b;
                    vertical-align: text-bottom;
                    border: 1px solid rgba(215, 222, 254, 1);
                    border-radius: 11px;
                    margin-left: 14px;
                }
                .list-delete:hover {
                    background: #f9faff;
                }
            }
        }
        .list-but {
            width: 100%;
            margin-top: 10px;
            button {
                height: 26px;
                color: rgb(107, 107, 107);
                padding: 0px 20px;
                margin-right: 14px;
                letter-spacing: -1px;
            }
            .but-left {
                background-color: rgb(189, 199, 252);
                border: none;
                color: #fff;
                font-weight: bold;
            }
            .but-right {
                display: inline-block;
                float: right;
                background-color: #ffffff;
                font-weight: bold;
                border: 1px solid #d7defe; // box-shadow: none;
                // z-index: 0;
            }
            .but-right:hover {
                background-color: rgb(189, 199, 252);
            }
        }
    }
    /*详情页表单样式*/
    .form-details-box {
        color: #6b6b6b;
        padding: 14px 96px;
        input {
            border-radius: 5px;
            border: 1px solid rgba(189, 199, 252, 1);
        }
    }
    /*配件项目样式*/
    .form-details-warp {
        color: #6b6b6b;
        padding: 5px 96px;
        input {
            border-radius: 8px; // border: 1px solid rgba(189, 199, 252, 1);
        }
        .form-title {
            display: block;
            height: 100%;
            line-height: 30px;
            font-size: 16px;
            font-weight: bold;
        }
        .from-line {
            border-top: 1px solid #bfbfbf;
            margin: 0 26px 26px;
        }
        .ant-input-affix-wrapper {
            border-radius: 8px;
        }
    }
    .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
        border-radius: 8px; // border: 1px solid rgba(215, 222, 254, 0) !important;
    }
    .pub-select {
        width: 100%;
        border-radius: 8px;
    }
    /*表单后缀地图按钮*/
    span.ant-input-group-addon {
        padding: 0;
        border: none;
    }
    .map-but {
        border-radius: 0 8px 8px 0;
    }
}

._chooseMapBox{
    /*表单后缀地图按钮*/
    span.ant-input-group-addon {
        padding: 0;
        border: none;
    }
    .map-but {
        border-radius: 0 8px 8px 0;
    }
}

.maintainOrderChooseTable .ant-checkbox-disabled{
    background-color: rgba(#000, 0.3);
}

.ant-table-thead th.ant-table-column-has-sorters:hover{
    background-color: #d7defe;
}