.dict-container {
    position: relative;
    height: calc(100vh - 156px);
    padding: 6px 10px 14px;
    .dict-list-box {
        display: inline-block;
        width: 37%;
        height: 100%;
        position: relative;
        background: #fff;
        box-shadow: 0px 5px 12px 3px rgba(33, 33, 33, 0.1);
        border-radius: 10px;
        margin-right: 1%;
        vertical-align: top;
        .dict-list-header {
            padding: 13px 8px 10px 10px;
            .dict-list-name {
                font-size: 18px;
                font-weight: bold;
                color: #434343;
            }
        }
        .dict-table-box {
            width: 100%;
            height: calc(100% - 96px);
            position: relative;
            overflow-y: auto;
            padding: 10px;
            border-top: 1px solid #ccc;
            font-size: 20px;
        }
    }
    .dict-from-box {
        display: inline-block;
        width: 62%;
        height: 100%;
        position: relative;
        overflow-y: auto;
        background: #fff;
        box-shadow: 0px 5px 12px 3px rgba(33, 33, 33, 0.1);
        border-radius: 10px;
        vertical-align: top;
        .dict-list-header {
            padding: 40px 8px 11px 10px;
        }
        .dict-from-content {
            padding: 10px;
            border-top: 1px solid #ccc;
        }
        .dict-init-box {
            width: 100%;
            height: 100%;
            text-align: center;
            span {
                font-size: 20px;
                font-weight: bold;
                line-height: 22;
            }
        }
    }
    /*按钮样式*/
    .dict-list-but {
        margin-top: 21px;
        .dict-but-left {
            background-color: rgb(189, 199, 252);
            color: rgb(107, 107, 107);
            border: none;
            padding: 0px 20px;
            margin-right: 14px;
            color: #fff;
            font-weight: bold;
        }
    }
}

@primary-color: #2e3aeb;@form-item-margin-bottom: 20px;@background-color-base: rgba(0, 0, 0, 0);@disabled-color: #808A87;@text-color: rgba(0, 0, 0, 0.65);@table-selected-row-hover-bg: #e9ecfb;@table-row-hover-bg: #e9ecfb;@table-header-bg: #d7defe;@table-header-color: #6b6b6b;@table-padding-vertical: 10px;@table-padding-horizontal: 6px;