.yh-list-container {
  background: #FFFFFF;
  border-radius: 10px;
  box-shadow: 0 5px 12px 3px rgba(33, 34, 34, 0.1);
  overflow: hidden;
  padding-top: 3px;
  margin: 0 10px 10px;
  &.inline-block {
    margin-right: 15px;
    margin-bottom: 10px;
  }
  .list-header {
    padding: 10px 30px 10px 10px;
    white-space: nowrap;
    color: #BDC7FC;
    &-title {
      font-size: 18px;
      font-weight: bold;
      color: #434343;
    }
  }
  .list-buttons {
    padding: 0 15px 10px;
  }
  .ant-btn {
    height: 26px;
    color: #6b6b6b;
    padding: 0px 20px;
    margin-right: 14px;
    letter-spacing: -1px;
    &[disabled],
    &[disabled]:hover,
    &[disabled]:focus,
    &[disabled]:active {
      background: #f5f5f5 !important;
      color: #aaa;
    }
  }
  .btn-item {
    background-color: #bdc7fc;
    border: none;
    color: #fff;
    font-weight: bold;
  }
}

.list-header-content {
  color: #333;
  &-item {
    font-size: 14px;
    margin-left: 40px;
    font-weight: bold;
  }
  &-statu-item {
    font-size: 14px;
    margin-right: 50px;
    font-weight: bold;
  }
  &-money {
    font-size: 18px;
  }
  &-statu {
    font-size: 18px;
  }
  .money-red {
    color: rgb(255, 11, 0);
  }
}

.money {
  &-red {
    color: rgb(255, 11, 0);
  }
  &-green {
    color: #028e19;
  }
  &-blue {
    color: #2e3aeb;
  }
}

.statu {
  &-red {
    color: rgb(255, 11, 0);
  }
  &-green {
    color: #028e19;
  }
  &-blue {
    color: #2e3aeb;
  }
  &-replenish {
    color: #d9001b;
  }
}
@primary-color: #2e3aeb;@form-item-margin-bottom: 20px;@background-color-base: rgba(0, 0, 0, 0);@disabled-color: #808A87;@text-color: rgba(0, 0, 0, 0.65);@table-selected-row-hover-bg: #e9ecfb;@table-row-hover-bg: #e9ecfb;@table-header-bg: #d7defe;@table-header-color: #6b6b6b;@table-padding-vertical: 10px;@table-padding-horizontal: 6px;