.ellipsis {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    margin: 0;
}
.site-tree-search-value {
    color: #f50;
}
.one-line {
    max-width: 180px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    margin: 0;
}

//标签栏样式
// .ant-tabs .ant-tabs-nav {
//     width: calc(100vw - 200px); // left: 0px;
//     position: fixed !important;
//     top: 64px;
//     z-index: 50;
//     background: #f9faff;
//     // border: 1px solid #00f;
// }
.ant-tabs-card > .ant-tabs-nav .ant-tabs-tab {
    margin-right: 8px !important;
    color: #2e3aeb;
    height: 32px !important;
    background-color: #fff !important;
    padding: 4px 16px !important;
    border: 1px solid rgba(215, 222, 254, 1) !important;
    box-shadow: 1px 2px 2px 1px rgba(34, 34, 34, 0.12);
    border-radius: 7px !important;
}

.ant-tabs > .ant-tabs-nav {
    margin: 0 0 6px 10px;
}

.ant-tabs > .ant-tabs-nav .ant-tabs-nav-list {
    margin: 6px 0;
}

.ant-tabs-dropdown-menu {
    transform: translateX(-86px) !important;
}

.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    font-weight: bold !important;
}

.ant-tabs-card > .ant-tabs-nav .ant-tabs-tab .ant-tabs-tab-remove {
    width: 16px !important;
    height: 16px !important;
    color: #ffffff !important;
    font-size: 10px !important;
    position: absolute;
    top: -5px;
    right: -2px;
    border-radius: 50%;
    background-color: #d81e06;
    display: flex;
    align-items: center;
    justify-content: center;
}

.ant-tabs-card > .ant-tabs-nav .ant-tabs-tab .ant-tabs-tab-remove:hover {
    color: #333 !important;
}

.ant-tabs-top > .ant-tabs-nav::before {
    border: none !important;
}

/*点选表格得每行样式*/

.dark-row,
.dark-row > .ant-table-cell-fix-left {
    color: #2e3aeb;
    background: #e9ecfb !important;
}

// @table-selected-row-hover-bg: #e9ecfb;
// @table-row-hover-bg: #e9ecfb;
// @table-header-bg: #d7defe;
// @table-header-color: #6b6b6b;
// @table-header-weight: bold;
// @table-padding-vertical: 15px;
// @table-padding-horizontal: 6px;
// @table-body-weight: bold;
// @table-font-size: 10px;
// @table-selected-row-color: @font-color-active;
// @table-selected-row-bg: #90A4FE;
// @table-border-radius-base: 0px;
// @tree-node-hover-bg: #ffffff;
.ant-table {
    // font-size: @font-size-sm;
    // font-weight: @table-body-weight;
    // line-height: @font-size-sm;
    &-thead {
        > tr {
            > th {
                font-weight: bold;
            }
        }
        & :hover th {
            cursor: pointer;
        }
    }
    &-tbody {
        /*偶数行*/
        & tr:nth-of-type(even) {
            background: #fbfcff;
        }
        /*奇数行*/
        & tr:nth-of-type(odd) {
            background: #fff;
        }
        & tr > .ant-table-cell-fix-left,
        & tr > .ant-table-cell-fix-right {
            background: inherit;
        }
        /*勾选的样式*/
        & tr.ant-table-row-selected > td {
            background: #e9ecfb !important;
        } //鼠标花过样式
        & tr:hover {
            cursor: pointer;
            color: #2e3aeb;
        }
    }
    &-row-selected &-cell {
        color: #2e3aeb;
    }
    &-selection {
        justify-content: center;
    }
}

/*可编辑单元格*/

.ant-table-tbody > tr.ant-table-row:hover > td > div.editable-cell-value-wrap {
    border: 1px solid #ccc;
}

.editable-cell-value-wrap {
    border: 1px solid rgba(255, 255, 255, 0);
    min-height: 32px;
    line-height: 32px;
    cursor: pointer;
}

.ant-table.ant-table-bordered tbody > tr > td {
    border: none !important;
}

// /*左侧菜单样式*/
.ant-menu-item-selected {
    font-weight: 600;
}

/*抽屉的样式*/

.ant-drawer-left,
.ant-drawer-right {
    top: 64px !important;
}

/*表单lable样式*/

.ant-form-item-label > label {
    color: #6b6b6b !important;
}

/*表单提示字体大小*/

.ant-form-item-has-error .ant-form-item-explain,
.ant-form-item-has-error .ant-form-item-split {
    margin: 0 !important;
    font-size: 12px !important;
}

/*地图信息框样式*/

.window-warp {
    width: 190px;
    position: relative;
    overflow: hidden;
    h4 {
        text-align: center;
    }
    .window-content {
        padding: 6px 0px 6px 6px;
        color: #878686;
    }
    img {
        width: 100%;
    }
}

.added-but {
    display: inline-block;
    width: 50px;
    text-align: center;
    color: #2d39eb;
    cursor: pointer;
}

.added-but1 {
    display: block;
    width: 50px;
    color: #2d39eb;
    cursor: pointer;
}

button.yc-public-but {
    height: 26px;
    color: rgb(107, 107, 107);
    padding: 0px 20px;
    margin-right: 10px;
    letter-spacing: -1px;
    background-color: rgb(189, 199, 252);
    border: none;
    color: #fff;
    font-weight: bold;
}

button.yc-public-but:hover {
    background-color: rgb(189, 199, 252);
}

/*antd上传组件 样式覆盖*/

.upLoad-img-box {
    margin-bottom: 12px;
}

.upLoad-img-box span.ant-badge {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.upLoad-img-box div.ant-image {
    height: 100%;
    line-height: 102px;
    overflow: hidden;
}

/*商品图片列表*/

.img-file-box {
    position: relative;
    width: 120px;
    height: 120px;
    border: 1px solid rgb(170, 170, 170);
    display: inline-block;
    margin-right: 10px;
    vertical-align: middle;
}

.img-file-box > .zhiding {
    position: absolute;
    top: 0;
    left: 0;
    width: 20px;
    height: 20px;
    border: 1px solid #ccc;
    font-size: 12px;
    line-height: 20px;
    text-align: center;
    color: #00f;
    cursor: pointer;
}

.ant-select-multiple div.ant-select-selector {
    border-radius: 8px;
}

.repair-img-box {
    width: 150px;
    height: 200px;
    display: inline-block;
    border: 2px solid #aaa;
    vertical-align: text-bottom;
    line-height: 192px;
    margin: 5px;
    overflow: hidden;
}

//调整上传查看和删除之间的间距
.ant-upload-list-picture-card .ant-upload-list-item-actions a {
    margin-right: 15px;
}

.ant-form-item-label {
    word-wrap: break-word;
    word-break: break-all;
}

.row-label {
    .ant-form-item-label {
        label {
            white-space: normal;
            // &:after {
            //     content: none !important;
            // }
        }
    }
}
