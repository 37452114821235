body {
    line-height: 1;
    min-width: 1300px;
    overflow: hidden;
    background-color: #f9faff;
    list-style: none;
    min-width: 375px;
}

/* 格式化ul,ol,li,去掉默认的样式 */

ol,
ul {
    list-style: none;
    margin: 0;
    padding: 0;
}

//文字不可复制
.non-copyable {
    -moz-user-select: none;
    /* Firefox私有属性 */
    -webkit-user-select: none;
    /* WebKit内核私有属性 */
    -ms-user-select: none;
    /* IE私有属性(IE10及以后) */
    -khtml-user-select: none;
    /* KHTML内核私有属性 */
    -o-user-select: none;
    /* Opera私有属性 */
    user-select: none;
    /* CSS3属性 */
}

/*滚动条样式*/

::-webkit-scrollbar {
    width: 8px;
    height: 10px;
    background-color: #f5f5f5;
}

::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: #f5f5f5;
}

::-webkit-scrollbar-thumb {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: #367fa9;
}

.width-100 {
    width: 100%;
}

.mr-10 {
    margin-right: 10px;
}

.mr-5 {
    margin-right: 5px;
}

.border-r-16 {
    border-radius: 16px;
}

// 溢出样式
.overflow-auto {
    overflow: auto;
}

.overflow-hidden {
    overflow: hidden;
}

.overflow-visible {
    overflow: visible;
}

.overflow-scroll {
    overflow: scroll;
}

.overflow-x-auto {
    overflow-x: auto;
}

.overflow-y-auto {
    overflow-y: auto;
}

.overflow-x-hidden {
    overflow-x: hidden;
}

.overflow-y-hidden {
    overflow-y: hidden;
}

.overflow-x-visible {
    overflow-x: visible;
}

.overflow-y-visible {
    overflow-y: visible;
}

.overflow-x-scroll {
    overflow-x: scroll;
}

.overflow-y-scroll {
    overflow-y: scroll;
}

// Display 布局方式
.block {
    display: block;
}

.inline-block {
    display: inline-block;
}

.inline {
    display: inline;
}

.flex {
    display: flex;
}

.inline-flex {
    display: inline-flex;
}

.table {
    display: table;
}

.hidden {
    display: none;
}

.translateX-50 {
    transform: translateX(50%);
} // Flex布局样式
.items-start {
    align-items: flex-start;
}

.items-end {
    align-items: flex-end;
}

.items-center {
    align-items: center;
}

.items-baseline {
    align-items: baseline;
}

.items-stretch {
    align-items: stretch;
}

.content-center {
    align-content: center;
}

.content-start {
    align-content: flex-start;
}

.content-end {
    align-content: flex-end;
}

.content-between {
    align-content: space-between;
}

.content-around {
    align-content: space-around;
}

.content-evenly {
    align-content: space-evenly;
}

.self-auto {
    align-self: auto;
}

.self-start {
    align-self: flex-start;
}

.self-end {
    align-self: flex-end;
}

.self-center {
    align-self: center;
}

.self-stretch {
    align-self: stretch;
}

.justify-items-auto {
    justify-items: auto;
}

.justify-items-start {
    justify-items: start;
}

.justify-items-end {
    justify-items: end;
}

.justify-items-center {
    justify-items: center;
}

.justify-items-stretch {
    justify-items: stretch;
}

.justify-start {
    justify-content: flex-start;
}

.justify-end {
    justify-content: flex-end;
}

.justify-center {
    justify-content: center;
}

.justify-between {
    justify-content: space-between;
}

.justify-around {
    justify-content: space-around;
}

.justify-evenly {
    justify-content: space-evenly;
}

.justify-self-auto {
    justify-self: auto;
}

.justify-self-start {
    justify-self: start;
}

.justify-self-end {
    justify-self: end;
}

.justify-self-center {
    justify-self: center;
}

.justify-self-stretch {
    justify-self: stretch;
}

.flex-1 {
    flex: 1 1 0%;
}

.flex-none {
    flex: none;
}
.flex-between {
    display: flex;
    justify-content: space-between;
}
.yh-editable-row .ant-col {
    height: auto;
}

//td 按钮容器
.td-wrap {
    padding: 0 !important;
    background: inherit;
}

//增加编辑表格 鼠标滑过样式
.editable-cell {
    position: relative;
}

.yh-editable-cell-value-wrap {
    min-height: 30px;
    line-height: 22px;
    padding: 3px;
    border-radius: 4px;
    cursor: pointer;
    border: 1px solid #bfbfbf;
    span {
        padding: 0;
    }
}

//修改可编辑表格的 padding
.yh-edit-table-tr {
    & > td {
        padding: 6px !important;
    }
}
.stock-td {
    background-color: #bfbfbf !important;
}

.yh-detail-box {
    height: calc(100vh - 182px);
    overflow-y: auto;
}

.ant-modal-footer {
    .ant-btn {
        height: 26px;
        // color: #6b6b6b;
        padding: 0px 20px;
        margin-right: 14px;
        letter-spacing: -1px;
    }
    .btn-item {
        background-color: #bdc7fc;
        border: none;
        color: #fff;
        font-weight: bold;
    }
}

//详情页贴下边的展示框
.details-bot-fixed-warp {
    position: fixed;
    right: 0;
    bottom: 0;
    height: 60px;
    background: #ffffff;
    box-shadow: 0px -1px 0px 0px rgba(33, 34, 34, 0.1);
    line-height: 60px;
    transition: all 0.2s;
}

//详情 更多 隐藏 col 栅格
.detail-note-col {
    .ant-form-item-label {
        display: block;
        flex: 0 0 30%;
        max-width: 30%;
    }
}

//重设置图片的col 栅格
.yh-file-image-box {
    .ant-form-item-label {
        flex: 0 0 6.333333%;
        max-width: 6.333333%;
    }
}

//lable 宽度
.yh-ant-col-7 {
    .ant-form-item-label {
        flex: 0 0 7%;
        max-width: 7%;
    }
}

.yh-ant-col-10 {
    .ant-form-item-label {
        flex: 0 0 10%;
        max-width: 10%;
    }
}

//checkbox   radio
.yh-ant-col-3 {
    .ant-form-item-label {
        flex: 0 0 3%;
        max-width: 3%;
        & > label::after {
            content: ' ';
        }
    }
    .ant-form-item-control .ant-checkbox-wrapper-checked,
    .ant-form-item-control .ant-radio-wrapper-checked {
        color: blue;
    }
}

//radio
.yh-ant-col {
    .ant-form-item-control .ant-radio-wrapper-checked {
        color: blue;
    }
}

//图片列表
.yh-img-file-box {
    position: relative;
    width: 120px;
    height: 122px;
    border: 1px solid #aaa;
    display: inline-block;
    margin-right: 10px;
    vertical-align: middle;
    span.ant-badge {
        width: 100%;
    }
    div.ant-image {
        width: 100%;
        text-align: center;
        height: 120px;
        line-height: 120px;
        overflow: hidden;
    }
}

.click-span {
    cursor: pointer;
    color: #2e3aeb;
}

.tab-buts-active {
    background-color: #2e3aeb !important;
    color: #fff !important;
}

//入库登记  记录表单框
.record-warp {
    display: flex;
    width: 100%;
    height: 36px;
    margin: 8px 0;
    border: 1px solid #aaa;

    .record-item {
        display: flex;
        width: 25%;

        label {
            width: 80px;
            text-align: center;
            line-height: 36px;
            border-right: 1px solid #aaa;
            border-left: 1px solid #aaa;
        }

        & .text {
            display: inline-block;
            width: 100%;
            padding-left: 8px;
            line-height: 36px;
            color: #aaa;
        }
    }
}

//tab按钮样式
.tab-item {
    border-radius: 5px;
    margin-left: 0px !important;
    margin-right: 5px;
    background-color: #bdc7fc;
    border: none;
    color: #fff;
    font-weight: bold;
}
.tab-active {
    background: #00f !important;
}

.ml-20 {
    margin-left: 20px;
}
// .ant-modal-wrap{
//     top: 120px;
//     height:  calc(100% - 120px);
// }

// .ant-modal-mask{
//     top: 120px;
//     height:  calc(100% - 120px);
// }

.background-FFB4B4 {
    background-color: #ffb4b4 !important;
}

.common-btn {
    background-color: #bdc7fc;
    border: none;
    color: #fff;
    font-weight: bold;
}

.common-btn:hover {
    background-color: #bdc7fc;
    color: #fff;
}

.custom-table tbody > tr > td{
    padding: 5px 10px; 
}

// @import "~antd/dist/antd.css";
@import './App.less';
@import './listWarpStyle.less';

@primary-color: #2e3aeb;@form-item-margin-bottom: 20px;@background-color-base: rgba(0, 0, 0, 0);@disabled-color: #808A87;@text-color: rgba(0, 0, 0, 0.65);@table-selected-row-hover-bg: #e9ecfb;@table-row-hover-bg: #e9ecfb;@table-header-bg: #d7defe;@table-header-color: #6b6b6b;@table-padding-vertical: 10px;@table-padding-horizontal: 6px;