.lit_title {
    display: block;
    margin-left: 26px;
    margin-bottom: 10px;
    font-size: 16px;
    font-weight: bold;
}
.editor_nargin {
    margin: 0 0 15px 26px;
    border: 1px solid #bfbfbf;
}
.bf-content {
    height: 300px !important;
}
.but-left {
    background-color: #bdc7fc;
    border: none;
    color: #fff;
    font-weight: bold;
}

@primary-color: #2e3aeb;@form-item-margin-bottom: 20px;@background-color-base: rgba(0, 0, 0, 0);@disabled-color: #808A87;@text-color: rgba(0, 0, 0, 0.65);@table-selected-row-hover-bg: #e9ecfb;@table-row-hover-bg: #e9ecfb;@table-header-bg: #d7defe;@table-header-color: #6b6b6b;@table-padding-vertical: 10px;@table-padding-horizontal: 6px;