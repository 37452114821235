 .print-container {
    width: 1000px;
    position: relative;
    //font-family: "FangSong";
    overflow: hidden;
    padding: 3px;
    margin: 0 auto;
    //font-weight: 600!important;
    /* font-size: 16px; */
}
 .print-container h2{
    text-align: center;
}
 .print-container  .header-odd{
    font-weight: 500;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}
 .print-container  .header-odd li{
    //margin-bottom:10px ;
    width:30%;
    white-space: nowrap;
/* .offerPrint-container .footer li { */
    /* display: inline-block;
    white-space: nowrap; */
}
.w30{
    width: 30% !important;
}
.w25{
    width: 25% !important;
}
.w10{
    width: 10% !important;
}
.w35{
    width: 35% !important;
}
.w40{
    width: 40% !important;
}
.w75{
    width: 75% !important;
}
.w90{
    width: 90% !important;
}
.li-left{
    text-align: left;
}
.li-right{
    text-align: right;
}
.li-center{
    text-align: center;
    width: 50% !important;
}
table {
    width: 100%;
    /* margin: 5px 0; */
}

table,
td {
    border-collapse: collapse;
    
}
 .print-container table{
    border-top:2px solid black;
    border-left:2px solid black;
    border-right:2px solid black;
}
 .footer-table{
    border-bottom:2px solid black;
}
/* 表格明细线 */
 .print-container td{
    font-weight: 500;
    padding: 3px 5px;
    text-align: center;
    border: 1px solid black;
}
 .print-container td:nth-child(1){
    width: 100px;
}
    .print-container td:last-child{
        width: 200px;
    }
/* .print-container td:nth-child(3){
    width: 100px !important;
} */
/*  .item-table td:nth-child(2){
    width: 250px;

}
 .material-table td:nth-child(2){
    width: 200px;    
} */
.print-container td:nth-child(1){
    width: 100px;
}
 .print-container td:last-child{
    width: 200px;
}
/*  .item-table td:nth-child(2){
    width: 250px;

}
 .material-table td:nth-child(2){
    width: 200px;    
} */
.finance .material-table td:nth-child(1) {
    width: 65px !important;

}
.finance .material-table td:nth-child(2) {
    width: 200px !important;
    text-align: left;
    white-space: nowrap;
    overflow: hidden !important;
}
.finance .material-table td:nth-child(3) {
    width: 200px !important;
    text-align: left;
    white-space: nowrap;
    overflow: hidden !important;
}
.finance .material-table td:nth-child(4) {
    width: 100px !important;
}
.finance .material-table td:nth-child(5) {
    width: 100px !important;
}
.finance .material-table td:nth-child(6) {
    width: 100px !important;
}
.finance .material-table td:nth-child(7) {
    width: 120px !important;
}
.finance .material-table td:nth-child(8) {
    width: 150px !important;
}
.finance .material-table td:nth-child(9) {
    width: 170px !important;
}