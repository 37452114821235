html {
    font-size: 18px;
}

.print-container {
    width: 1150px;
    position: relative;
    overflow: hidden;
    padding: 0 3px;
    margin: 0 auto;
    color:black;
}

.print-container .title {
    margin-bottom: 0;
    text-align: center;
    font-size:24px;
    font-family: FangSong;
    color: rgba(0, 0, 0, 0.85);
    font-weight: 500;
}

.print-container .scmheader {
    margin-bottom: 0;
    width: 100%;
}

.print-container .scmheader li,
.print-container .footer li {
    display: inline-block;
    width: 33.33%;
    white-space: nowrap;
    font-size:16px;
    font-family: FangSong;
    color: rgba(0, 0, 0, 0.85);
    

}

.print-container .footer li.note {
    width: 89%;
    height: 46px;
    white-space: normal;
    position: relative;
    overflow: hidden;
}

.print-container .footer li.pagination {
    width: 10%;
    text-align: right;
}

.print-container .footer li.w20 {
    width: 20%;
}
.print-container .footer li.w16 {
    width: 16.5%;
}

.print-container .scmheader li.w20 {
    width: 20%;
}
.print-container .scmheader li.w25 {
    width: 25%;
}
.print-container .scmheader li.w30 {
    width: 30%;
}
.print-container .scmheader li.w40 {
    width: 40%;
}
.print-container .scmheader li.w45 {
    min-width: 45%;
    max-width:45%;
}
.print-container .scmheader li.h34 {
    height: 34px;
}

.print-container .scmheader li.h34_w30 {
    height: 34px;
    width: 30%;
}

.print-container .scmheader li.h34_w40 {
    height: 34px;
    width: 40%;
}

.mingxi {
    width: 100%;
}

.mingxi,
td {
    border-collapse: collapse;
}


/*明细表格线*/

.mingxi {
    margin: 0;
}

.mingxi td,
.mingxi th {
    border: 1px solid #000;
}

.mingxi td,
.mingxi th {
    padding: 3px 1px;
    text-align: left;
    font-family: FangSong; 
    color: rgba(0, 0, 0, 0.85); 
}

.print-center td {
    text-align: center;
    white-space: nowrap;
    width:100px;
    font-family: FangSong;   
    color: rgba(0, 0, 0, 0.85);   
}


.print-center td:nth-child(1) {
    /* width: 90px; */
    width: 55px;
    text-align: center;

}

.print-center td:nth-child(2) {
    /* width: 150px; */
    width: 240px;
    text-align: left;
}
.print-center td:nth-child(3) {
    /* width: 150px; */
    width: 540px;
    text-align: left;
}
.print-center td:nth-child(4) {
    width: 50px;
    text-align: center;
}
.print-center td:nth-child(5) {
    width: 80px;
    text-align: center;
}
.print-center td:nth-child(6) {
    width: 80px;
    text-align: center;
}
.print-center td:nth-child(7) {
    width: 120px;
    text-align: center;
}
.print-center td:nth-child(8) {
    width: 140px;
    text-align: center;
}
.print-center td:nth-child(9) {
    width: 140px;
    text-align: center;
}

.print-center td:last-child {
    width: 150px;
}
.f-W600{
    font-weight: 600;
    
}