.upload {
    position: relative;
    width: 375px;

    .upload-box {
        margin-top: 15px;
        width: 375px;
        padding: 12px 16px 5px 22px;
        background-color: #fff;
        box-sizing: border-box;
        .upload-title {
            margin-bottom: 10px;
            display: flex;
            align-items: center;
            .title-ipt {
                width: 100%;
                border: none;
                outline: none;
            }
        }
        .upload-img {
            display: flex;
            flex-wrap: nowrap;
        }
        .upload-msg {
            height: 17px;
            margin-top: 10px;
            color: #8c8c8c;
        }
    }
    .save-btn {
        position: fixed;
        top: 500px;
        left: 60px;
        margin: 0 auto;
        width: 255px;
        height: 40px;
        background-color: #479fef;
        line-height: 40px;
        text-align: center;
        font-size: 16px;
        color: #fff;
        border-radius: 4px;
        box-sizing: border-box;
    }
}

.upload-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 145px;
    height: 98px;
    background-color: rgba(242, 242, 242, 1);
    border-radius: 4px;
}

@primary-color: #2e3aeb;@form-item-margin-bottom: 20px;@background-color-base: rgba(0, 0, 0, 0);@disabled-color: #808A87;@text-color: rgba(0, 0, 0, 0.65);@table-selected-row-hover-bg: #e9ecfb;@table-row-hover-bg: #e9ecfb;@table-header-bg: #d7defe;@table-header-color: #6b6b6b;@table-padding-vertical: 10px;@table-padding-horizontal: 6px;