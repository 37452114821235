.team-list-but {
    margin-top: 21px;
    margin-bottom: 21px;
    .team-but-left {
        background-color: rgb(189, 199, 252);
        color: rgb(107, 107, 107);
        border: none;
        padding: 0px 20px;
        margin-right: 14px;
        color: #fff;
        font-weight: bold;
    }
    .team-list-but-right {
        display: inline-block;
        float: right;
        .team-but-right {
            background-color: #fff;
            color: #6b6b6b;
            font-weight: bold;
            border: 1px solid #d7defe;
            padding: 0px 20px;
        }
    }
}

@primary-color: #2e3aeb;@form-item-margin-bottom: 20px;@background-color-base: rgba(0, 0, 0, 0);@disabled-color: #808A87;@text-color: rgba(0, 0, 0, 0.65);@table-selected-row-hover-bg: #e9ecfb;@table-row-hover-bg: #e9ecfb;@table-header-bg: #d7defe;@table-header-color: #6b6b6b;@table-padding-vertical: 10px;@table-padding-horizontal: 6px;