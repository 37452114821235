.teamManage-container {
    position: relative;
    height: calc(100vh - 156px);
    padding: 6px 10px 14px; // border:1px solid #00f;
    // display: none;
    /*header 按钮样式*/
    .team-list-but {
        margin-top: 21px;
        .team-but-left {
            background-color: rgb(189, 199, 252);
            color: rgb(107, 107, 107);
            border: none;
            padding: 0px 20px;
            margin-right: 14px;
            color: #fff;
            font-weight: bold;
        }
        .team-list-but-right {
            display: inline-block;
            float: right;
            .team-but-right {
                background-color: #fff;
                color: #6b6b6b;
                font-weight: bold;
                border: 1px solid #d7defe;
                padding: 0px 20px;
            }
        }
    }
    .team-list-box {
        display: inline-block;
        width: 100%;
        height: 100%;
        position: relative;
        background: #fff;
        box-shadow: 0px 5px 12px 3px rgba(33, 33, 33, 0.1);
        border-radius: 10px;
        margin-right: 1%;
        vertical-align: top;
        .team-list-header {
            padding: 13px 8px 10px 10px;
            .team-list-name {
                font-size: 18px;
                font-weight: bold;
                color: #434343;
            }
        }
        .team-table-box {
            width: 100%;
            height: calc(100% - 96px);
            position: relative;
            overflow-y: auto;
        }
    }
    .team-member-box {
        display: inline-block;
        width: 62%;
        height: 100%;
        position: relative;
        overflow-y: auto;
        background: #fff;
        box-shadow: 0px 5px 12px 3px rgba(33, 33, 33, 0.1);
        border-radius: 10px;
        vertical-align: top;
        .team-list-header {
            padding: 40px 8px 11px 10px;
        }
    }
}

/*编辑页的样式,订单新增 项目弹框，物料弹框*/

.team-drawer-title {
    color: #fdfbfb;
    font-size: 18px;
    font-weight: bold;
    text-align: center;
}

.team-drawer-content {
    width: 100%;
    height: calc(100% - 42px);
    margin-top: 18px;
    border-radius: 10px;
    background: #fff;
    overflow-y: auto;
    /*评价图片*/
    .lookImg {
        display: inline-block;
        width: 33%;
        padding: 5px;
        vertical-align: top;
        border: 1px dashed #aaa;
    }
    /*添加搜索框*/
    .drawer-search-box {
        text-align: right;
        padding: 10px;
        .search-input1 {
            width: 160px;
            border-radius: 8px;
            margin: 0px 10px;
        }
        .search-lable1 {
            display: inline-block;
            width: 100px;
        }
        .search-but1 {
            border-radius: 8px;
        }
    }
    .team-drawer-header {
        height: 40px;
        line-height: 40px;
        text-align: center;
        font-size: 16px;
        color: #4a4b4c;
        font-weight: bold;
        background: #d2d2d2;
        border-radius: 10px 10px 0 0;
    }
    .team-drawer-form {
        padding: 30px;
        input {
            border-radius: 6px; // border: 1px solid rgba(189, 199, 252, 1);
        }
        .team-form-footer {
            margin-top: 30px;
            text-align: center;
            .team-but-one {
                display: inline-block;
                width: 30%;
                min-width: 100px;
                max-width: 195px;
                height: 36px;
                background: #7d7d7d;
                border-radius: 4px;
                margin-right: 100px;
            }
            .team-but-two {
                display: inline-block;
                width: 30%;
                min-width: 100px;
                max-width: 195px;
                height: 36px;
                background: #039cfd;
                border-radius: 4px;
            }
        }
    } //评价标签样式
    .comment-tags {
        display: inline-block;
        color: #eee;
        font-weight: bold;
        padding: 0px 20px;
        border-radius: 5px;
    }
    .team-form-footer {
        margin-top: 18px;
        text-align: center;
        .team-but-one {
            display: inline-block;
            width: 30%;
            min-width: 100px;
            max-width: 195px;
            height: 36px;
            background: #7d7d7d;
            border-radius: 4px;
            margin-right: 100px;
            color: #eee;
            font-size: 16px;
        }
        .team-but-two {
            display: inline-block;
            width: 30%;
            min-width: 100px;
            max-width: 195px;
            height: 36px;
            background: #039cfd;
            border-radius: 4px;
            color: #eee;
            font-size: 16px;
        }
    }
    .select-item-box {
        padding: 10px;
        li {
            padding: 5px 15px;
            border-bottom: 1px dashed #ccc;
            span {
                display: inline-block;
                text-align: center;
            }
            & span:first-child {
                font-weight: bold;
            }
            & span:last-child {
                float: right;
                width: 40px;
                height: 22px;
                border-radius: 3px;
                cursor: pointer;
                background: #039cfd;
            }
        }
    }
}

@primary-color: #2e3aeb;@form-item-margin-bottom: 20px;@background-color-base: rgba(0, 0, 0, 0);@disabled-color: #808A87;@text-color: rgba(0, 0, 0, 0.65);@table-selected-row-hover-bg: #e9ecfb;@table-row-hover-bg: #e9ecfb;@table-header-bg: #d7defe;@table-header-color: #6b6b6b;@table-padding-vertical: 10px;@table-padding-horizontal: 6px;