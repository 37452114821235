.xDrawerWrap {
    position: fixed;
    right: 0;
    top: 64px;
    height: 100%;
    width: 1000px;
    overflow: hidden;
    z-index: 1000;
    transition: all 0.6s;
    .xDrawerMask {
        height: calc(100% - 64px);
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        background-color: #000;
        opacity: 0;
    }
    .xDrawerContent {
        height: calc(100% - 64px);
        position: absolute;
        top: 0;
        right: 0;
        width: 900px;
        min-width: 650px !important;
        padding: 16px;
        background: rgba(0, 0, 0, 0.4);
        border-radius: 10px;
        transition: all 0.6s;
        padding: 20px 17px;
        .xCloseBtn {
            position: absolute;
            top: 0px;
            right: 17px;
            color: #ccc;
            font-size: 36px;
            cursor: pointer;
        }
    }
}

@primary-color: #2e3aeb;@form-item-margin-bottom: 20px;@background-color-base: rgba(0, 0, 0, 0);@disabled-color: #808A87;@text-color: rgba(0, 0, 0, 0.65);@table-selected-row-hover-bg: #e9ecfb;@table-row-hover-bg: #e9ecfb;@table-header-bg: #d7defe;@table-header-color: #6b6b6b;@table-padding-vertical: 10px;@table-padding-horizontal: 6px;