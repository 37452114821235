.yh-drawer {
    position: fixed;
    left: 0;
    top: 64px;
    height: 100%;
    width: 100%;
    overflow: hidden;
    z-index: 1000;
    transition: all 0.6s;
    .yh-drawer-mask {
        height: calc(100% - 64px);
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        background-color: #000;
        opacity: 0;
    }
    .yh-drawer-body {
        height: calc(100% - 64px);
        position: absolute;
        top: 0;
        right: 0;
        min-width: 400px !important;
        padding: 16px;
        background: rgba(0, 0, 0, 0.4);
        border-radius: 10px;
        transition: all 0.6s;
        padding: 20px 17px;
        .yh-drawer-title {
            color: #fdfbfb;
            font-size: 18px;
            font-weight: bold;
            text-align: center;
        }
        .yh-drawer-content {
            width: 100%;
            height: calc(100% - 42px);
            margin-top: 18px;
            padding-bottom: 46px;
            border-radius: 10px;
            background: #fff;
            overflow-y: auto;
        }
        .yh-drawer-footer {
            position: absolute;
            bottom: 20px;
            left: 0;
            text-align: center;
            width: 100%;
            .btn-item {
                display: inline-block;
                width: 30%;
                min-width: 100px;
                max-width: 195px;
                height: 36px;
                border-radius: 4px;
                color: #eee;
                font-size: 16px;
            }
            & .btn-item:first-child {
                background: #7d7d7d;
                margin-right: 100px;
            }
            & .btn-item:last-child {
                background: #039cfd;
            }
        }
        .close-btn {
            position: absolute;
            top: 0px;
            right: 17px;
            color: #ccc;
            font-size: 36px;
            cursor: pointer;
        }
    }
}
@primary-color: #2e3aeb;@form-item-margin-bottom: 20px;@background-color-base: rgba(0, 0, 0, 0);@disabled-color: #808A87;@text-color: rgba(0, 0, 0, 0.65);@table-selected-row-hover-bg: #e9ecfb;@table-row-hover-bg: #e9ecfb;@table-header-bg: #d7defe;@table-header-color: #6b6b6b;@table-padding-vertical: 10px;@table-padding-horizontal: 6px;