
.offerPrint-container{
    width: 1150px;
    margin: 0 auto;
    font-size: 14px;
}
.title{
    text-align: center;
    margin-bottom: 0;
}
.offerPrint-container>.offerPrint-header{
    width: 100%;
    display: flex;
    justify-content: space-between;
    /* justify-content: flex-start; */
    flex-wrap: wrap;


    
}
.offerPrint-container .offerPrint-header li{
    margin-bottom:10px ;
    width:50%;
    white-space: nowrap;
/* .offerPrint-container .footer li { */
    /* display: inline-block;
    white-space: nowrap; */
}
.w30{
    width: 30% !important;
}
.w20{
    width: 20% !important;
}
.li-right{
    text-align: right;
}
/* 明细表格线 */
.mingxi{
    border:3px solid black;
}
.mingxi td,
.mingxi th {
    border: 1px solid black;
    padding: 3px 1px;
    /* text-align: left; */
}
.border-right-none{
    border-right: 1px solid transparent !important;
}
.border-left-none{
    border-right: 1px solid transparent !important;
}
.print-left{
    text-align: left;
    padding: 3px 1px;
}
.print-center td {
    text-align: center;
    /* white-space: nowrap; */
}

.print-center td:nth-child(1) {
    /* width: 90px; */
    width: 55px;
    text-align: center;
}

.print-center td:nth-child(2) {
    /* width: 150px; */
    width: 240px;
    text-align: left;
}
.print-center td:nth-child(3) {
    /* width: 150px; */
    width: 340px;
    text-align: left;
}
.print-center td:nth-child(4) {
    width: 50px;
    text-align: center;
}
.print-center td:nth-child(5) {
    width: 80px;
    text-align: center;
}
.print-center td:nth-child(6) {
    width: 80px;
    text-align: center;
}
.print-center td:nth-child(7) {
    width: 120px;
    text-align: center;
}
.print-center td:nth-child(8) {
    width: 140px;
    text-align: center;
}
.print-center td:nth-child(9) {
    width: 140px;
    text-align: center;
}

.print-center td:last-child {
    width: 150px;
}
.print-total{
    width: 100%;
    height: 25px;
    line-height: 25px;
    /* display: flex; */
    /* justify-content: space-between; */
    
    border-bottom:1px solid black
}
.print-total span{
    width: 40%;
    display: inline-block;
}
/* 底部信息展示 */
.offerPrint-container .footer p{
    margin: 5px;
}
.offerPrint-container .footer .footer-people{
    width: 100%;
    margin-top: 10px;
    padding-left: 5px;
    margin-bottom: 0;
}
.offerPrint-container .footer .footer-people li{
    width: 20%;
    display: inline-block;
}
/* .note-footer{
    padding-bottom:5px;
    border-bottom: 1px dashed black;
} */