/* @media print { */
.print-container {
    width: 1060px;
    position: relative;
    /* overflow: hidden; */
    padding: 0 6px 10px;
    /* margin:0 auto; */
    font-family: "SimSun";
    text-shadow:0.15pt 0px 0px black,0.25pt 0px 0px black,0.35pt 0px 0px black,-0.25pt 0px 0px black,0px 0.25pt 0px black,0px -0.25pt 0px black;
    
}

.print-container .title {
    font-size: 20px;
    font-weight: bold;
    text-align: center;
}

.print-name {
    margin: 6px 0px 6px;
    position: relative;
}
.print-name > div {
    display: inline-block;
    width: 25%;
}
.print-table {
    width: 1060px;
    border-bottom: 1px solid #000;
    border-left: 2px solid #000;
    border-right: 2px solid #000;
    border-top: 0 solid #000;
    font-size: 18px;
}

.print-table-first{
    border-top: 2px solid #000;
}

.print-table-last{
    border-bottom: 2px solid #000;
}


.print-table,
td {
    border-collapse: collapse;
}

.print-table td {
    padding: 4px 0;
    border: 1px solid #333;
    text-align: center;
}

/*头*/
/*底部*/
.print-table .table-head td:nth-child(odd),
.print-table .table-footer td:nth-child(odd) {
    width: 110px; 
}

.print-table .table-head td:nth-child(even),
.print-table .table-footer td:nth-child(even) {
    width: 160px;
    text-align: left;
}

/*项目*/
.print-table .table-item td:nth-child(1) {
    width: 160px;
}

.print-table .table-item td:nth-child(3),
.print-table .table-item td:nth-child(4) {
    width: 100px;
}

/*配件*/
.print-table .table-prot td:nth-child(1),
.print-table .table-prot td:nth-child(4){
    width: 60px; 
}

.print-table .table-prot td:nth-child(5){
    width: 90px !important;
}
.print-table .table-prot td:nth-child(6){
    width: 100px !important;
}

.print-table .table-prot td:nth-child(2){
    width: 200px;
}
.print-table .table-prot td:nth-child(3){
    width: 260px;
}


/*其他项目*/
.print-table .table-other td:nth-child(1),
.print-table .table-other td:nth-child(4){
    width: 60px;
}

.print-table .table-other td:nth-child(2){
    width: 200px;
}
.print-table .table-other td:nth-child(3){
    width: 240px;
}

.print-table .table-other td:nth-child(5),
.print-table .table-other td:nth-child(6){
    width: 100px;
}


/*字体*/
.print-table .table-item,
.print-table .table-other,
.print-table .table-prot {
    /* font-size: 18px; */
    font-size: 18px !important;
}

.print-table .table-item:first-child td,
.print-table .table-other:first-child td,
.print-table .table-prot:first-child td {
    font-size: 18px;
}

/*去掉多余线*/
.print-table .table-head:last-child td,
.print-table .table-item:last-child td,
.print-table .table-other:last-child td,
.print-table .table-prot:last-child td {
    border-bottom: none;
}

.print-footer {
    margin-top: 10px;
}

.print-footer div {
    display: inline-block;
}

/* .print-footer div:first-child {
    margin-right: 160px;
} */
/* } */